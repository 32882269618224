<template lang="pug">
div
  .col-12
    om-heading.mb-6(h3) {{ $t('needHelp.title') }}
  .d-flex.flex-wrap.col-12.pt-2
    .row.no-gutters
      .col-12.col-lg-4.box-item(
        v-for="(item, index) in helpTexts"
        :class="`box-item-${index + 1}`"
      )
        img.box-img(
          :src="require(`@/assets/admin/svg/needHelp/en_${index + 1}.png`)"
          :class="`box-img-${index + 1}`"
        )
        .box-item-content.box-item-link(@click="redirectToSales(index)")
          .d-flex.flex-wrap.w-100.flex-column(style="flex: auto")
            om-heading.w-100.mb-3(h5) {{ $t(item.title) }}
            om-body-text.w-100.box-item-desc(bt400sm) {{ $t(item.desc) }}
          om-link.mt-3.-w-100.align-self-end(primary small withIconRight) {{ $t(item.cta) }}
</template>
<script>
  export default {
    data() {
      return {
        helpTexts: [
          { title: 'needHelp.first.title', desc: 'needHelp.first.lead', cta: 'needHelp.first.cta' },
          {
            title: 'needHelp.second.title',
            desc: 'needHelp.second.lead',
            cta: 'needHelp.second.cta',
          },
          { title: 'needHelp.third.title', desc: 'needHelp.third.lead', cta: 'needHelp.third.cta' },
        ],
      };
    },
    methods: {
      redirectToSales(index) {
        const link = [
          'https://www.optimonk.com/conversion-rate-optimization-course/',
          'https://www.optimonk.com/getting-started-with-optimonk-course/?utm_source=admin&utm_medium=banner&utm_campaign=need-help-section',
          'https://www.optimonk.com/optimonk-product-lessons/?utm_source=admin&utm_medium=banner&utm_campaign=need-help-section',
        ];

        window.open(link[index]);
      },
    },
  };
</script>
<style lang="sass">
  .box-item
    .box-img
      width: 116px
</style>
